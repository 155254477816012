import { defineStore } from "pinia";
import { i18n } from "@/config/i18n";
import {
  getSafetyFactorOptions,
  VIEW_FACTORS_WITHOUT_GRADIENT,
} from "@/pages/safety-factor/config";
import { computed, ref, watch } from "vue";
import { getUncertaintiesWithColors, soilTypeColors } from "@/colorLegends";
import { unitsConverter } from "@/config/units";
import {
  findMainUncertainty,
  getSafetyLevel,
} from "@/pages/safety-factor/helpers";
import {
  getMinMaxValue,
  rangeFiltering,
  valueFiltering,
} from "@/utils/filterHelpers";
import { usePipePartsStore } from "./usePipePartsStore";
import { usePipelinesStore } from "./usePipelinesStore";
import { mergeChanges } from "@/utils/objects";

const requests = new Map();

const INITIAL_PLOTLY_FILTER = { property: "", range: [] };

const INITIAL_SAFETY_FACTOR_FILTERS = {
  selectedMainUncertainties: [],
  selectedSafetyRange: [],
  selectedLowerSafetyRange: [],
  selectedUpperSafetyRange: [],
  plotlyFilter: INITIAL_PLOTLY_FILTER,
};

export const useSafetyFactorStore = defineStore("safetyFactor", () => {
  const pipePartsStore = usePipePartsStore();
  const pipelinesStore = usePipelinesStore();

  const shouldGetNewSafetyFactor = ref(true);

  watch(
    () => pipelinesStore.selectedInspectionId,
    () => {
      shouldGetNewSafetyFactor.value = true;
    }
  );

  const isLoading = ref(false);

  function setLoading(loading) {
    isLoading.value = loading;
  }

  const selectedPipePartId = ref(null);

  const selectedPipePart = computed(() => {
    if (!selectedPipePartId.value) return;

    return pipePartsStore.pipeParts.find(
      ({ id }) => id === selectedPipePartId.value
    );
  });

  function setSelectedPipePartId(pipePartId) {
    selectedPipePartId.value = pipePartId;
  }

  const filters = ref(INITIAL_SAFETY_FACTOR_FILTERS);

  const hasActiveFilters = computed(() =>
    Object.entries(filters.value).some(([key, value]) => {
      if (["selectedMainUncertainties"].includes(key)) {
        return value.length;
      }
      const rangeMap = {
        selectedSafetyRange: minMaxSafetyRange.value,
        selectedLowerSafetyRange: minMaxLowerSafetyRange.value,
        selectedUpperSafetyRange: minMaxUpperSafetyRange.value,
      };

      if (key in rangeMap) {
        const [min, max] = rangeMap[key];
        return min !== value[0] || max !== value[1];
      }
    })
  );
  const filteredPipeParts = computed(() => {
    const {
      plotlyFilter,
      selectedSafetyRange,
      selectedLowerSafetyRange,
      selectedUpperSafetyRange,
      selectedMainUncertainties,
    } = filters.value;
    const uncertaintiesWithColors = getUncertaintiesWithColors(i18n.global.t);

    let filtered = pipePartsStore.pipeParts.map((part) => {
      const converted = {
        ...part,
        safety_factor_calculation: { ...part.safety_factor_calculation },
      };

      converted.distance = unitsConverter.instance.convert(
        converted.distance,
        "m"
      );
      converted.length = unitsConverter.instance.convert(converted.length, "m");

      if (!converted.safety_factor_calculation) {
        return converted;
      }

      [
        "ground_level_mean",
        "ground_level_std",
        "ground_water_level_min",
        "ground_water_level_max",
        "road_distance_mean",
        "road_distance_std",
      ].forEach((key) => {
        converted.safety_factor_calculation[key] =
          unitsConverter.instance.convert(
            converted.safety_factor_calculation[key],
            "m"
          );
      });

      const mainUncertainty = findMainUncertainty(converted);

      converted.safety_factor_calculation.main_uncertainty =
        uncertaintiesWithColors[mainUncertainty];

      return converted;
    });

    filtered = rangeFiltering(
      filtered,
      "safety_factor_calculation.safety_factor",
      selectedSafetyRange
    );

    filtered = rangeFiltering(
      filtered,
      "safety_factor_calculation.safety_factor_lower",
      selectedLowerSafetyRange
    );

    filtered = rangeFiltering(
      filtered,
      "safety_factor_calculation.safety_factor_upper",
      selectedUpperSafetyRange
    );

    filtered = valueFiltering(
      filtered,
      "safety_factor_calculation.main_uncertainty.text",
      selectedMainUncertainties
    );

    if (plotlyFilter.property !== "") {
      const { property, range } = plotlyFilter;

      filtered = rangeFiltering(
        filtered,
        "safety_factor_calculation." + property,
        range
      );
    }

    return filtered;
  });

  function setFilters(newFilters) {
    mergeChanges(filters.value, newFilters);
    selectedPipePartId.value = null;
  }
  function initFilters() {
    setFilters({
      selectedSafetyRange: minMaxSafetyRange.value,
      selectedLowerSafetyRange: minMaxLowerSafetyRange.value,
      selectedUpperSafetyRange: minMaxUpperSafetyRange.value,
    });
  }
  function resetPlotlyFilter() {
    setFilters({ plotlyFilter: INITIAL_PLOTLY_FILTER });
  }
  function resetFilters() {
    filters.value = INITIAL_SAFETY_FACTOR_FILTERS;
  }

  const selectedViewFactor = ref(
    getSafetyFactorOptions(i18n.global.t)[1].value
  );

  const gradientMinMaxValues = computed(() => {
    if (VIEW_FACTORS_WITHOUT_GRADIENT.includes(selectedViewFactor.value)) {
      return [];
    }

    return getMinMaxValue(
      filteredPipeParts.value,
      "safety_factor_calculation." + selectedViewFactor.value
    );
  });
  const uniqueSafetyLevels = computed(() => {
    if (
      !["safety_factor", "safety_factor_lower", "safety_factor_upper"].includes(
        selectedViewFactor.value
      )
    ) {
      return [];
    }

    const uniqueList = new Set();

    pipePartsStore.pipeParts.forEach((part) => {
      if (part.safety_factor_calculation) {
        const level = getSafetyLevel(
          part.safety_factor_calculation[selectedViewFactor.value]
        );

        uniqueList.add(level);
      }
    });

    const orderedLevelsList = Array.from(uniqueList).sort((a, b) =>
      a.index > b.index ? 1 : -1
    );

    return orderedLevelsList;
  });
  const legendList = computed(() => {
    if (selectedViewFactor.value.includes("safety_factor")) {
      return uniqueSafetyLevels.value;
    }

    if (selectedViewFactor.value === "main_contributor") {
      return uniqueUncertaintiesOptions.value;
    }

    if (selectedViewFactor.value === "soil_type") {
      return uniqueSoilTypes.value;
    }

    return [];
  });

  function setSelectedViewFactor(viewFactor) {
    selectedViewFactor.value = viewFactor;
  }

  const selectedLegend = ref(null);

  function setSelectedLegend(legend) {
    selectedLegend.value = legend;
  }
  function toggleSelectedLegeng(legend) {
    if (selectedLegend.value === legend) {
      setSelectedLegend(null);
      return;
    }

    setSelectedLegend(legend);
  }

  const minMaxSafetyRange = computed(() =>
    getMinMaxValue(
      pipePartsStore.pipeParts,
      "safety_factor_calculation.safety_factor",
      true
    )
  );
  const minMaxLowerSafetyRange = computed(() =>
    getMinMaxValue(
      pipePartsStore.pipeParts,
      "safety_factor_calculation.safety_factor_lower",
      true
    )
  );
  const minMaxUpperSafetyRange = computed(() =>
    getMinMaxValue(
      pipePartsStore.pipeParts,
      "safety_factor_calculation.safety_factor_upper",
      true
    )
  );
  const uniqueUncertaintiesOptions = computed(() => {
    const allMainUncertainties = pipePartsStore.pipeParts.map((part) =>
      findMainUncertainty(part)
    );

    const uniqueList = Array.from(new Set(allMainUncertainties));
    const uncertaintiesWithColors = getUncertaintiesWithColors(i18n.global.t);

    return uniqueList.map((key) => uncertaintiesWithColors[key]);
  });
  const uniqueSoilTypes = computed(() => {
    const uniqueList = new Set();

    pipePartsStore.pipeParts.forEach((part) => {
      if (part.safety_factor_calculation) {
        uniqueList.add(part.safety_factor_calculation.soil_type);
      }
    });

    const uniqueSoilTypesWithColors = Array.from(uniqueList).map(
      (type) => soilTypeColors[type]
    );

    return uniqueSoilTypesWithColors;
  });

  async function initSafetyFactor() {
    setLoading(true);
    await pipePartsStore.getPipeParts();
    initFilters();
    requests.delete("pipePartsRequest");
    setLoading(false);
    shouldGetNewSafetyFactor.value = false;
  }
  function cancelAllRequests() {
    for (const request of requests.values()) {
      request.cancel();
    }
  }

  return {
    shouldGetNewSafetyFactor,
    isLoading,
    selectedPipePartId,
    filters,
    selectedViewFactor,
    selectedLegend,
    minMaxSafetyRange,
    minMaxLowerSafetyRange,
    minMaxUpperSafetyRange,
    uniqueUncertaintiesOptions,
    uniqueSoilTypes,
    gradientMinMaxValues,
    uniqueSafetyLevels,
    legendList,
    selectedPipePart,
    hasActiveFilters,
    filteredPipeParts,
    setLoading,
    setSelectedPipePartId,
    setFilters,
    initFilters,
    resetPlotlyFilter,
    resetFilters,
    setSelectedViewFactor,
    setSelectedLegend,
    toggleSelectedLegeng,
    initSafetyFactor,
    cancelAllRequests,
  };
});
