import { computed, ref, watch } from "vue";
import { defineStore } from "pinia";
import { i18n } from "@/config/i18n";
import {
  DigUpSelector,
  getDataForDigupRequest,
} from "@/features/dig-up/helpers";
import { unitsConverter } from "@/config/units";
import {
  getMaxAngle,
  getMinMaxValue,
  getUniqueValues,
  rangeFiltering,
  valueFiltering,
} from "@/utils/filterHelpers";
import { getQuantile } from "@/pages/joints/helpers";
import { auth0 } from "@/config/auth0";
import { generateDigupSheet } from "@/features/dig-up/api";
import { useAuthStore } from "./useAuthStore";
import { useToast } from "vue-toast-notification";
import { DefaultService, OpenAPI } from "@/open-api-code/ili-api";
import { usePipelinesStore } from "./usePipelinesStore";
import { useMapStore } from "./useMapStore";
import { useSettingsStore } from "./useSettingsStore";
import { mergeChanges } from "@/utils/objects";

const INITIAL_JOINT_FILTERS = {
  selectedJointTypes: [],
  selectedMaterials: [],
  selectedVerticalAngle: 0,
  selectedHorizontalAngle: 0,
  selectedGapMean: [],
  selectedGapMax: [],
  plotlyFilter: {
    property: "",
    range: [],
  },
};

const toast = useToast();
const requests = new Map();

export const useJointsStore = defineStore("joints", () => {
  const mapStore = useMapStore();
  const settingsStore = useSettingsStore();
  const pipelinesStore = usePipelinesStore();
  const authStore = useAuthStore();

  const shouldGetNewJoints = ref(true);

  watch(
    () => pipelinesStore.selectedInspectionId,
    () => {
      shouldGetNewJoints.value = true;
    }
  );

  const joints = ref([]);

  const jointsWithConvertedUnits = computed(() =>
    joints.value.map((joint) => {
      return {
        ...joint,
        distance:
          joint.distance &&
          unitsConverter.instance.convert(joint.distance, "m"),
        gap_width_max:
          joint.gap_width_max &&
          unitsConverter.instance.convert(joint.gap_width_max, "mm"),
        gap_width_mean:
          joint.gap_width_mean &&
          unitsConverter.instance.convert(joint.gap_width_mean, "mm"),
      };
    })
  );
  const jointsWithIds = computed(() => {
    const sortedJoints = jointsWithConvertedUnits.value.sort(
      (a, b) => a.distance - b.distance
    );
    return sortedJoints.map((joint, index) => ({
      ...joint,
      joint_id: index + 1,
    }));
  });
  const jointTypes = computed(() =>
    getUniqueValues(joints.value, "joint_type")
  );
  const materials = computed(() => getUniqueValues(joints.value, "material"));
  const maxVerticalAngle = computed(() =>
    getMaxAngle(joints.value, "angle_vertical")
  );
  const maxHorizontalAngle = computed(() =>
    getMaxAngle(joints.value, "angle_horizontal")
  );
  const quantileVertical = computed(() =>
    getQuantile(
      joints.value.map((j) => j["angle_vertical"]),
      0.99
    )
  );
  const quantileHorizontal = computed(() =>
    getQuantile(
      joints.value.map((j) => j["angle_horizontal"]),
      0.99
    )
  );
  const minMaxGapMean = computed(() =>
    getMinMaxValue(jointsWithIds.value, "gap_width_mean")
  );
  const minMaxGapMax = computed(() =>
    getMinMaxValue(jointsWithIds.value, "gap_width_max")
  );

  function setJoints(value) {
    joints.value = value;
  }
  async function getJoints() {
    try {
      setIsLoading(true);
      OpenAPI.TOKEN = await auth0.getAccessTokenSilently();
      requests.set(
        "jointsRequest",
        DefaultService.readJointsInlineInspectionsJointsInspectionIdGroupGet(
          pipelinesStore.selectedInspectionId,
          authStore.selectedGroup
        )
      );
      const joints = await requests.get("jointsRequest");
      requests.delete("jointsRequest");
      setJoints(joints);
      initFilters();

      shouldGetNewJoints.value = false;
    } catch (error) {
      if (error.name !== "CancelError") {
        console.log(error);
        toast.error(`Read Joints - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  const jointFilters = ref(INITIAL_JOINT_FILTERS);

  const hasActiveFilters = computed(() => {
    return (
      jointFilters.value.selectedJointTypes.length > 0 ||
      jointFilters.value.selectedMaterials.length > 0
    );
  });
  const filteredJoints = computed(() => {
    const {
      selectedJointTypes,
      selectedMaterials,
      selectedVerticalAngle,
      selectedHorizontalAngle,
      selectedGapMax,
      selectedGapMean,
      plotlyFilter,
    } = jointFilters.value;

    let filtered = jointsWithIds.value;

    if (selectedJointTypes.length) {
      filtered = valueFiltering(filtered, "joint_type", selectedJointTypes);
    }

    if (selectedMaterials.length) {
      filtered = valueFiltering(filtered, "material", selectedMaterials);
    }

    if (!mapStore.isMapMode) {
      filtered = rangeFiltering(filtered, "angle_vertical", [
        selectedVerticalAngle * -1,
        selectedVerticalAngle,
      ]);

      filtered = rangeFiltering(filtered, "angle_horizontal", [
        selectedHorizontalAngle * -1,
        selectedHorizontalAngle,
      ]);

      filtered = rangeFiltering(filtered, "gap_width_max", selectedGapMax);
      filtered = rangeFiltering(filtered, "gap_width_mean", selectedGapMean);
    }

    if (plotlyFilter.property !== "") {
      const { property, range } = plotlyFilter;
      filtered = rangeFiltering(filtered, property, range);
    }

    return filtered;
  });

  function setFilters(filters) {
    mergeChanges(jointFilters.value, filters);
    jointsSelector.value.clear();
  }
  function initFilters() {
    setFilters({
      selectedVerticalAngle: maxVerticalAngle.value,
      selectedHorizontalAngle: maxHorizontalAngle.value,
      selectedGapMean: minMaxGapMean.value,
      selectedGapMax: minMaxGapMax.value,
    });
  }
  function resetFilters() {
    setFilters(INITIAL_JOINT_FILTERS);
  }

  const selectedJointId = ref(null);

  const selectedJoint = computed(() =>
    filteredJoints.value.find((joint) => joint.id === selectedJointId.value)
  );

  function setSelectedJointId(jointId) {
    selectedJointId.value = jointId;
  }

  const isLoading = ref(true);

  function setIsLoading(value) {
    isLoading.value = value;
  }

  const isAwaitingDigup = ref(false);

  function setIsAwaitingDigup(value) {
    isAwaitingDigup.value = value;
  }

  const jointsSelector = ref(new DigUpSelector(i18n.global.locale));

  const areAllJointsSelected = computed(() => {
    return jointsSelector.value.size === filteredJoints.value.length;
  });

  const selectedGradientMode = ref("degradation_level");

  function setSelectedGradientMode(gradientMode) {
    selectedGradientMode.value = gradientMode;
  }

  const selectedLegend = ref(null);

  function setSelectedLegend(legend) {
    selectedLegend.value = legend;
  }
  function toggleSelectedLegend(legend) {
    if (selectedLegend.value === legend) {
      setSelectedLegend(null);
    } else {
      setSelectedLegend(legend);
    }
  }

  async function getDigupSheet() {
    try {
      setIsAwaitingDigup(true);

      const requestData = getDataForDigupRequest(
        jointsSelector.value.value,
        joints.value,
        settingsStore.locale,
        settingsStore.units
      );

      const token = await auth0.getAccessTokenSilently();
      await generateDigupSheet(token, authStore.selectedGroup, requestData);
    } catch (error) {
      if (error.name !== "CancelError") {
        console.error(error);
        toast.error(`Digup generating - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      setIsAwaitingDigup(false);
    }
  }

  function cancellAllRequests() {
    for (const request of requests.values()) {
      request.cancel();
    }
  }

  return {
    shouldGetNewJoints,
    joints,
    jointFilters,
    selectedJointId,
    isLoading,
    isAwaitingDigup,
    jointsSelector,
    selectedGradientMode,
    selectedLegend,
    areAllJointsSelected,
    jointsWithConvertedUnits,
    jointsWithIds,
    jointTypes,
    materials,
    maxVerticalAngle,
    maxHorizontalAngle,
    quantileVertical,
    quantileHorizontal,
    minMaxGapMean,
    minMaxGapMax,
    selectedJoint,
    hasActiveFilters,
    filteredJoints,
    setJoints,
    getJoints,
    setSelectedJointId,
    setFilters,
    initFilters,
    resetFilters,
    setIsLoading,
    setIsAwaitingDigup,
    getDigupSheet,
    cancellAllRequests,
    toggleSelectedLegend,
    setSelectedGradientMode,
  };
});
