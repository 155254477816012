<template>
  <v-layout class="layout">
    <v-navigation-drawer
      :rail="rail"
      @update:rail="onRail"
      expand-on-hover
      v-model="drawer"
      permanent
      class="drawer"
      theme="dark"
    >
      <v-list nav>
        <v-img
          :width="300"
          aspect-ratio="16/9"
          cover
          src="img/no-payoff.png"
          class="logo"
        ></v-img>
        <v-btn
          class="rail-toggler"
          variant="text"
          :icon="rail ? 'mdi-view-list' : 'mdi-dots-vertical'"
          @click.stop="rail = !rail"
        />
        <v-divider :thickness="2"></v-divider>
        <v-list-item
          prepend-icon="mdi-view-dashboard"
          :title="$t('side_bar.routes_name.dashboard')"
          :to="`${PAGES.DASHBOARD.PATH}?${searchParamsString}`"
        ></v-list-item>
        <template v-if="pipelinesStore.selectedPipeline">
          <v-list-item
            :disabled="!pipelinesStore.selectedInspectionStats"
            prepend-icon="mdi-chart-box-outline"
            :title="$t('side_bar.routes_name.anomalies')"
            :to="`${PAGES.ANOMALIES.PATH}?${searchParamsString}`"
          ></v-list-item>
          <v-list-item
            :disabled="!pipelinesStore.selectedInspectionStats"
            prepend-icon="mdi-chart-box-outline"
            :title="$t('side_bar.routes_name.joints')"
            :to="`${PAGES.JOINTS.PATH}?${searchParamsString}`"
          ></v-list-item>
          <v-list-item
            :disabled="!pipelinesStore.selectedInspectionStats"
            prepend-icon="mdi-chart-box-outline"
            :title="$t('side_bar.routes_name.pipe_parts')"
            :to="`${PAGES.PIPE_PARTS.PATH}?${searchParamsString}`"
          ></v-list-item>
          <v-list-item
            :disabled="
              !pipelinesStore.selectedInspectionStats ||
              !pipelinesStore.selectedInspectionStats.safety_factor_available
            "
            prepend-icon="mdi-chart-box-outline"
            :title="$t('side_bar.routes_name.safety_factor')"
            :to="`${PAGES.SAFETY_FACTOR.PATH}?${searchParamsString}`"
          ></v-list-item>
        </template>
        <v-list-item
          v-if="scannerControlStore.scannersService"
          prepend-icon="mdi-map-marker-multiple-outline"
          :title="$t('side_bar.routes_name.scanner_control')"
          :to="PAGES.SCANNER_CONTROL.PATH"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-cog"
          :title="$t('side_bar.routes_name.settings')"
          :to="`${PAGES.SETTINGS.PATH}?${searchParamsString}`"
        ></v-list-item>
        <v-list-item @click="onClickShowScanners" :active="false">
          <template v-slot:prepend>
            <div class="switch">
              <v-switch
                :model-value="mapStore.showMapScanners"
                :hide-details="true"
                :loading="
                  isLoadingPipeScanners[ScannerType.Results] ? 'warning' : false
                "
                :disabled="isLoadingPipeScanners[ScannerType.Results]"
                density="compact"
              ></v-switch>
            </div>
          </template>
          <template v-slot:title>{{ $t("side_bar.show_scanners") }} </template>
        </v-list-item>
        <v-list-item @click="mapStore.switchMapmode" :active="false">
          <template v-slot:prepend>
            <div class="switch">
              <v-switch
                :model-value="mapStore.isMapMode"
                :hide-details="true"
                density="compact"
              ></v-switch>
            </div>
          </template>
          <template v-slot:title>{{ $t("side_bar.map_mode") }} </template>
        </v-list-item>
        <NavBarGroupItem
          :showNested="showNested"
          @onSelect="onNavigationGroupSelect"
        ></NavBarGroupItem>
        <v-divider :thickness="2"></v-divider>
        <NavBarAdminItem
          v-if="authStore.isAdmin"
          :showNested="showNested"
        ></NavBarAdminItem>
        <v-list-item
          prepend-icon="mdi-login-variant"
          title="Logout"
          @click="logout"
        ></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main :class="{ expanded: rail }">
      <slot></slot>
    </v-main>
  </v-layout>
</template>

<script>
import NavBarGroupItem from "./NavBarGroupItem.vue";
import NavBarAdminItem from "./NavBarAdminItem.vue";

import { RESULTS } from "@/features/pipescanner/constants";
import { administrationAPI } from "@/features/administration/api";
import { ScannerType } from "@/pages/scanner-control/config";
import {
  useAuthStore,
  useGlobalStore,
  useMapStore,
  usePipelinesStore,
  usePipeScannersStore,
  useScannerControlStore,
} from "@/store";
import { useSelectedPipelineIdInURL } from "@/utils/urlHelpers";
import { PAGES } from "@/constants/pagesNames";
import { onMounted, watch } from "vue";

export default {
  components: {
    NavBarGroupItem,
    NavBarAdminItem,
  },

  setup() {
    const { pipelineIdInURL } = useSelectedPipelineIdInURL();
    const pipelinesStore = usePipelinesStore();
    const authStore = useAuthStore();

    onMounted(async () => {
      await authStore.fetchPermissionsAndGroups();

      watch(
        () => [pipelineIdInURL.value, pipelinesStore.groupPipelines.length],
        pipelinesStore.setSelectedPipelineIdFromURL,
        { immediate: true }
      );
    });

    return {
      ScannerType,
      administrationAPI,
      pipelineIdInURL,
      PAGES,
      pipelinesStore,
      authStore,
      mapStore: useMapStore(),
      pipeScannersStore: usePipeScannersStore(),
      scannerControlStore: useScannerControlStore(),
      globalStore: useGlobalStore(),
    };
  },

  data() {
    return {
      drawer: true,
      rail: false,
      showNested: false,
      requests: new Map(),
    };
  },

  computed: {
    isLoadingPipeScanners() {
      return this.pipeScannersStore.isLoading;
    },
    searchParamsString() {
      return new URLSearchParams(this.$route.query).toString();
    },
  },

  methods: {
    logout() {
      this.$auth0.logout({
        logoutParams: { returnTo: window.location.origin },
      });
    },

    onNavigationGroupSelect(group) {
      if (group !== this.authStore.selectedGroup) {
        this.pipelinesStore.setIsLoadingPipelines(true);

        this.globalStore.clearAllSelectedData();
        this.authStore.setSelectedGroup(group);

        const path = this.$router.currentRoute.value.path;

        const { DASHBOARD, SCANNER_CONTROL } = PAGES;

        if (path !== DASHBOARD.PATH && path !== SCANNER_CONTROL.PATH) {
          this.$router.push(DASHBOARD.PATH);
        }

        this.authStore.getGroupData();
      }
    },

    onRail(value) {
      if (this.rail && value) {
        this.showNested = false;
      } else {
        this.showNested = true;
      }
    },

    onClickShowScanners() {
      if (this.isLoadingPipeScanners[ScannerType.Results]) {
        return;
      }

      this.mapStore.switchMapScanners();

      if (
        this.mapStore.showMapScanners &&
        this.pipeScannersStore.pipeScanners[RESULTS].length === 0
      ) {
        this.pipeScannersStore.fetchResultsScanners();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.layout {
  height: 100vh;

  .v-main {
    transition: width 0.5s;
    padding: 0 !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 256px;
    right: 0;

    &.expanded {
      left: 56px;
    }
  }
}

.drawer {
  background: $black-primary !important;
  position: relative;
  z-index: 10000 !important;

  .logo {
    margin: 15px 0;
  }

  div.switch {
    width: 56px;
  }

  :deep(.rail-toggler) {
    position: absolute;
    top: 15px;
    left: 200px;
  }

  :deep(.v-list-item) {
    border-radius: 10px;
    margin-top: 6px;
    cursor: pointer;
    &:hover {
      background: #3b4151;
    }
  }

  :deep(.v-list-item--active) {
    background: linear-gradient(60deg, $cyan-main, $cyan-dark);
  }

  :deep(.v-list-item-title) {
    font-size: 14px !important;
  }
}
</style>
