"use strict";
import { OpenAPI } from "../core/OpenAPI";
import { request } from "../core/request";
export class DefaultService {
  /**
   * Read Pipelines
   * endpoint to read pipelines data
   * Arguments
   * ---------
   * skip (int, optional): skip param for limit. Defaults to 0.
   * limit (int, optional): limit param for limit. Defaults to 100.
   * db (Session, optional): db connection. Defaults to Depends(get_db).
   *
   * Returns
   * -------
   * list(dict): list of dictionaries containing pipeline data
   * @param group
   * @param skip
   * @param limit
   * @returns PipelineRead Successful Response
   * @throws ApiError
   */
  static readPipelinesInlineInspectionsPipelinesGroupGet(
    group,
    skip,
    limit = 100
  ) {
    return (0, request)(OpenAPI, {
      method: "GET",
      url: "/inline-inspections/pipelines/{group}",
      path: {
        group: group,
      },
      query: {
        skip: skip,
        limit: limit,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Read Critical Thresholds
   * endpoint to read_critical_thresholds for given pipeline id
   * Arguments
   * ---------
   * db (Session, optional): db connection. Defaults to Depends(get_db).
   *
   * Returns
   * -------
   * list(dict): list of dictionaries containing critical_thresholds data
   * @param pipelineId
   * @param group
   * @returns CriticalThreshold Successful Response
   * @throws ApiError
   */
  static readCriticalThresholdsInlineInspectionsCriticalThresholdsPipelineIdGroupGet(
    pipelineId,
    group
  ) {
    return (0, request)(OpenAPI, {
      method: "GET",
      url: "/inline-inspections/critical-thresholds/{pipeline_id}/{group}",
      path: {
        pipeline_id: pipelineId,
        group: group,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Read Critical Thresholds Or Default
   * @param inspectionId
   * @param group
   * @returns CriticalThresholdsOrDefault Successful Response
   * @throws ApiError
   */
  static readCriticalThresholdsOrDefaultInlineInspectionsCriticalThresholdsOrDefaultInspectionIdGroupGet(
    inspectionId,
    group
  ) {
    return (0, request)(OpenAPI, {
      method: "GET",
      url: "/inline-inspections/critical-thresholds-or-default/{inspection_id}/{group}",
      path: {
        inspection_id: inspectionId,
        group: group,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Create Critical Thresholds
   * @param group
   * @param requestBody
   * @returns CriticalThreshold Successful Response
   * @throws ApiError
   */
  static createCriticalThresholdsInlineInspectionsCriticalThresholdsGroupPost(
    group,
    requestBody
  ) {
    return (0, request)(OpenAPI, {
      method: "POST",
      url: "/inline-inspections/critical-thresholds/{group}",
      path: {
        group: group,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Critical Threshold
   * @param id
   * @param group
   * @returns any Successful Response
   * @throws ApiError
   */
  static deleteCriticalThresholdInlineInspectionsCriticalThresholdsIdGroupDelete(
    id,
    group
  ) {
    return (0, request)(OpenAPI, {
      method: "DELETE",
      url: "/inline-inspections/critical-thresholds/{id}/{group}",
      path: {
        id: id,
        group: group,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Read Inspections
   * read all inspections in database endpoint
   *
   * Arguments
   * ---------
   * db (Session, optional): db session. Defaults to Depends(get_db).
   *
   * Returns
   * -------
   * list(dict): list of dicts of inspections
   * @param group
   * @returns InspectionRead Successful Response
   * @throws ApiError
   */
  static readInspectionsInlineInspectionsInspectionsGroupGet(group) {
    return (0, request)(OpenAPI, {
      method: "GET",
      url: "/inline-inspections/inspections/{group}",
      path: {
        group: group,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Read Inspections
   * Read an inspection by its inspection id
   *
   * Arguments
   * ---------
   * id (str): id of inspection
   * db (Session, optional): db session. Defaults to Depends(get_db).
   *
   * Returns
   * -------
   * list(dict): list of dicts of inspections
   * @param id
   * @param group
   * @returns InspectionRead Successful Response
   * @throws ApiError
   */
  static readInspectionsInlineInspectionsInspectionsIdGroupGet(id, group) {
    return (0, request)(OpenAPI, {
      method: "GET",
      url: "/inline-inspections/inspections/{id}/{group}",
      path: {
        id: id,
        group: group,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Read Pipe Parts
   * read pipe parts for inspection
   *
   * Arguments
   * ---------
   * inspection_id (str): inspection id
   * db (Session, optional): db session. Defaults to Depends(get_db).
   *
   * Returns
   * -------
   * list(dict): list of dicts of pipe parts
   * @param inspectionId
   * @param group
   * @returns PipePartRead Successful Response
   * @throws ApiError
   */
  static readPipePartsInlineInspectionsPipePartsInspectionIdGroupGet(
    inspectionId,
    group
  ) {
    return (0, request)(OpenAPI, {
      method: "GET",
      url: "/inline-inspections/pipe-parts/{inspection_id}/{group}",
      path: {
        inspection_id: inspectionId,
        group: group,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Read Joints
   * read joints for inspection
   *
   * Arguments
   * ---------
   * inspection_id (str): inspection id
   * db (Session, optional): db session. Defaults to Depends(get_db).
   *
   * Returns
   * -------
   * list(dict): list of dicts of joints
   * @param inspectionId
   * @param group
   * @returns JointRead Successful Response
   * @throws ApiError
   */
  static readJointsInlineInspectionsJointsInspectionIdGroupGet(
    inspectionId,
    group
  ) {
    return (0, request)(OpenAPI, {
      method: "GET",
      url: "/inline-inspections/joints/{inspection_id}/{group}",
      path: {
        inspection_id: inspectionId,
        group: group,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Read Anomalies
   * read anomalies for inspection
   *
   * Arguments
   * ---------
   * inspection_id (str): inspection id
   * anomaly_type (str, optional): filter on anomaly type. Defaults to None.
   * db (Session, optional): db session. Defaults to Depends(get_db).
   *
   * Returns
   * -------
   * list(dict): list of dicts of anomalies
   * @param inspectionId
   * @param group
   * @param anomalyType
   * @returns AnomalyRead Successful Response
   * @throws ApiError
   */
  static readAnomaliesInlineInspectionsAnomaliesInspectionIdGroupGet(
    inspectionId,
    group,
    anomalyType
  ) {
    return (0, request)(OpenAPI, {
      method: "GET",
      url: "/inline-inspections/anomalies/{inspection_id}/{group}",
      path: {
        inspection_id: inspectionId,
        group: group,
      },
      query: {
        anomaly_type: anomalyType,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Read Anomaly Detailed
   * read detailed version of the anomaly
   *
   * Arguments
   * ---------
   * anomaly_id (str): anomaly id
   * db (Session, optional): db session. Defaults to Depends(get_db).
   *
   * Returns
   * -------
   * dict: dict detailed anomaly
   * @param anomalyId
   * @param group
   * @returns AnomalyReadDetailed Successful Response
   * @throws ApiError
   */
  static readAnomalyDetailedInlineInspectionsAnomaliesDetailedAnomalyIdGroupGet(
    anomalyId,
    group
  ) {
    return (0, request)(OpenAPI, {
      method: "GET",
      url: "/inline-inspections/anomalies-detailed/{anomaly_id}/{group}",
      path: {
        anomaly_id: anomalyId,
        group: group,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Read Inspection Stats
   * read inspection statistics
   *
   * Arguments
   * ---------
   * inspection_id (str): inspection id
   * db (Session, optional): db session. Defaults to Depends(get_db).
   *
   * Returns
   * -------
   * dict: dictionary with inspection stats
   * @param inspectionId
   * @param group
   * @returns InspectionStats Successful Response
   * @throws ApiError
   */
  static readInspectionStatsInlineInspectionsInspectionStatsInspectionIdGroupGet(
    inspectionId,
    group
  ) {
    return (0, request)(OpenAPI, {
      method: "GET",
      url: "/inline-inspections/inspection-stats/{inspection_id}/{group}",
      path: {
        inspection_id: inspectionId,
        group: group,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
